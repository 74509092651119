.home-container{
	width: 100%;
	height: 100%;

	
}

.home-page{
	height: calc(90vh - 90px);
	width: 100%;
	gap: .5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10vh;
	justify-content: center;
}

.home-page h1{
	font-size: var(--heading-1-font-size);
}

.home-page h3{
	font-size: var(--heading-4-font-size);
	font-weight: 100;
}

.about-container{
	padding: 8rem 12rem;
	width: 100%;
	height: 100%;
	background-color: lightgray;
	gap: 3rem;
	display: flex;
	flex-direction: column;

	
}

.about-paragraph{
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.title-container{
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
}

.line{
	
	border: 1px solid rgb(58, 58, 58);

	width: 40%;
}

.title-container h2{
	font-size: var(--heading-2-font-size);
}

.about-container p{
	line-height: 2rem;
}

.experiance-div{
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.experiance-container{
	padding: 8rem 12rem;
	width: 100%;
	min-height: 100vh;
	height: 100%;

	gap: 3rem;
	display: flex;
	flex-direction: column;
	
	
}

.experiance-item{
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	gap: 1.5rem;
	
}

.experiance-item-left{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: .5rem;
	width: 25%;
	
}

.experiance-item-top h2{
	font-size: var(--heading-4-font-size);
}

.experiance-item-top h3{
	font-size: var(--body-font-size);
}

.experiance-item-left h4{
	font-size: var(--caption-font-size);
}


.experiance-l-line{
	width: 100%;
	border: .5px solid rgb(0, 0, 0);
}

.experiance-item-right{
	display: flex;
	flex-direction: column;
	
	width: 65%;
}

.experiance-line{
	border: 1px solid black;
	height: 10rem;

}

.experiance-item-right ul {
	display: flex;
	flex-direction: column;
	gap: .6rem;
	line-height: 1.6rem;
}

.projects-container{
	padding: 8rem 12rem;
	width: 100%;
	min-height: 100vh;
	background-color: lightgray;
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.project-items{
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.each-project{
	display: flex;
	align-items: center;
	
	justify-content: space-between;
}

.each-project.two {
	display: flex;
	flex-direction: row-reverse; /* This will swap the order of the child elements */
	
	align-items: center;
  }

.image-div{
	width: 40%;
	height: 100%;
	background-color: bisque;
}

.each-project-left{
	width: 55%;
	gap: 1rem;
	line-height: 1.6rem;
	display: flex;
	flex-direction: column;
	
}

.each-project-left h2{
	font-size: var(--heading-2-font-size);
}

.each-project-left p{
	font-size: var(--body-font-size);
}

.project-tools p{
	font-size: var(--caption-font-size);
}

.image-div img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.each-project-left hr{
	border: 1px solid black;
}

.project-tools{
	display: flex;
	gap: 1rem;
}

.each-project-left button{
	width: 50%;
	background-color: transparent;
	padding: 1rem 0rem;
	font-size: var(--body-font-size);
	cursor: pointer;
	border: 1px solid black;
}

.contact-container{
	padding: 8rem 12rem;
	width: 100%;

	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.contact-message{
	text-align: center;
	display: flex;
	justify-content: center;
}

.contact-message p{
	width: 70%;
	line-height: 1.6rem;
	font-size: var(--body-font-size);
}

.contact-container button{
	width: 133px;
	height: 35px;
	border-radius: 5px;
	margin: 0 auto;
	background-color: transparent;
	border: 1px solid rgb(112, 112, 112);
	cursor: pointer;
}

.contact-container button a{
	text-decoration: none;
	color: black;
	font-size: var(--caption-font-size);
	
}

.contact-social-icons{
	display: flex;
	justify-content: center;
	
	gap: 1rem;
	width: 100%;
}

.contact-social-icons a{
	text-decoration: none;
	color: black;
}

.contact-social{
	width: 2rem;
	height: 2rem;
}

@media (max-width: 600px) {
	
	.title-container h2{
		font-size: var(--heading-3-font-size);
		text-align: center;
	}


	.home-page h1{
		font-size: var(--heading-3-font-size);
	}
	
	.home-page h3{
		font-size: var(--heading-5-font-size);
		font-weight: 100;
	}

	.about-container{
		padding: 5rem 1rem;
		width: 100%;
		height: 100%;
		background-color: lightgray;
		gap: 3rem;
		display: flex;
		flex-direction: column;
	
		
	}

	.experiance-div{
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
	
	.experiance-container{
		padding: 5rem 1rem;
		width: 100%;
		min-height: 100vh;
		height: 100%;
	
		gap: 3rem;
		display: flex;
		flex-direction: column;
		
		
	}
	
	.experiance-item{
		display: flex;
		flex-direction: column;
		align-items: normal;
		width: 100%;
		height: 100%;
		justify-content: space-between;
		gap: 1.5rem;
		
	}
	
	.experiance-item-left{
		display: flex;
		flex-direction: column;
		align-items: normal;
		gap: .5rem;
		width: 100%;
		
	}
	
	.experiance-item-top h2{
		font-size: var(--heading-5-font-size);
	}
	
	.experiance-item-top h3{
		font-size: var(--caption-font-size);
	}

	.experiance-item-right ul li{
		font-size: var(--caption-font-size);
	}
	
	.experiance-item-left h4{
		font-size: var(--caption-font-size);
	}
	
	
	.experiance-l-line{
		width: 100%;
		border: .5px solid rgb(0, 0, 0);
	}
	
	.experiance-item-right{
		display: flex;
		flex-direction: column;
		
		width: 100%;
	}
	
	.experiance-line{
		border: 1px solid black;
		height: 10rem;
		display: none;
	
	}
	
	.experiance-item-right ul {
		display: flex;
		flex-direction: column;
		padding: 0rem 1rem;
		gap: .6rem;
		line-height: 1.6rem;
	}
	.projects-container{
		padding: 5rem 1rem;
		width: 100%;
		min-height: 100vh;
		background-color: lightgray;
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
	
	.project-items{
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
	
	.each-project{
		display: flex;
		flex-direction: column;
		align-items: normal;
		gap: 1.5rem;
		justify-content: space-between;
	}
	.each-project-left h2{
		line-height: 1.7rem;
	}
	
	.each-project.two {
		display: flex;
		flex-direction:column; /* This will swap the order of the child elements */
		
		align-items: center;
	  }
	
	.image-div{
		width: 100%;
		height: 100%;
		background-color: bisque;
	}
	
	.each-project-left{
		width: 100%;
		gap: 1rem;
		line-height: 1.6rem;
		display: flex;
		flex-direction: column;
		
	}
	
	.each-project-left h2{
		font-size: var(--heading-2-font-size);
	}
	
	.each-project-left p{
		font-size: var(--body-font-size);
	}
	
	.project-tools p{
		font-size: var(--caption-font-size);
	}
	
	.image-div img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	.each-project-left hr{
		border: 1px solid black;
	}
	
	.project-tools{
		display: flex;
		gap: 1rem;
	}
	
	.each-project-left button{
		width: 100%;
		background-color: transparent;
		padding: 1rem 0rem;
		font-size: var(--body-font-size);
		cursor: pointer;
		border: 1px solid black;
	}

	.contact-container{
		padding: 5rem 1rem;
		width: 100%;
	
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
	
	.contact-message{
		text-align: center;
		display: flex;
		justify-content: center;
	}
	
	.contact-message p{
		width: 90%;
		line-height: 1.6rem;
		font-size: var(--body-font-size);
	}
	
	.contact-container button{
		width: 133px;
		height: 35px;
		border-radius: 5px;
		margin: 0 auto;
		background-color: transparent;
		border: 1px solid rgb(112, 112, 112);
		cursor: pointer;
	}
	
	.contact-container button a{
		text-decoration: none;
		color: black;
		font-size: var(--caption-font-size);
		
	}
	
	.contact-social-icons{
		display: flex;
		justify-content: center;
		
		gap: 1rem;
		width: 100%;
	}
	
	.contact-social-icons a{
		text-decoration: none;
		color: black;
	}
	
	.contact-social{
		width: 2rem;
		height: 2rem;
	}
	
}