.header-container{
    height: 90px;
    width: 100%;
    padding: .5rem 12rem;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.header-left img{
    width: 3rem;
    height: 3rem;
}

.header-middle{
    cursor: pointer;
    display: flex;
    gap: 1.5rem;
}

.header-middle a{
    text-decoration: none;
    color: black;
    font-weight: 300;
    font-size: var(--body-font-size);
}



.header-right button{
    font-size: var(--body-font-size);
    width: 81px;
    height: 35px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid darkgray;
    font-weight: 300;
    border-radius: 5px;

}
.sub-menu.active{
    color: rgba(0, 0, 0, 0.918) !important;
}

.sub-menu{
    display: none;
    right: 1rem;
    position: fixed;
    color: white;
}

.responsive-nav{
    position: absolute;
    top: -500%;
    width: 100%;
    min-height: 100vh;
    background-color: #f2f2f2;
    opacity: 0;
    z-index: 5;
    transition: all cubic-bezier(0.19, 1, 0.22, 1)1s;
    
}

.res-close{
    position: absolute;
    top: 2%;
    right: 2%;
    font-size: 3rem;
}

.responsive-nav button{
    padding: .5rem 2rem;
    border-radius: 5px;
}





@media (max-width: 600px) {

    .sub-menu {
        display: block;
        height: 3rem;
        width: 2rem;
     
    }

    .sub-menu {
        display: block;
        font-size: 2rem;
    }

    .header-middle{
        display: none;
      
    }   
    .header-left {
        width: 3.1rem;
        height: 3.1rem;
        
    }

    .header-left img{
        width: 100%;
     
        height: 100%;
    }

    .header-container{
        height: 100px;
        width: 100%;
        padding: .5rem 1rem;
      
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-right{
        display: none;
    
    }

    
    .responsive-nav{
        
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
        justify-content: center;
    }

    .responsive-nav.active {
        position: fixed;
        top: 0;
        opacity: 1;
      }
      

    .responsive-nav a{
        text-decoration: none;
    color: black;
    transition: color 0.3s ease;
    cursor: pointer;
    font-size: 2rem;
    text-transform: uppercase;

    }
    .res-dropdown-content a{
        font-size: 1rem ;
        text-align: center;
    }

    .res-navLinks{
        font-size: 2rem;
        text-transform: uppercase;
    }

  

    .header-logo1-responsive{
        
            height:10rem;
            width: 10rem;
            z-index: 100;
      
    }
    
}